@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.active {
  color: #f36c00 !important;
}
